<template>
  <b-card class="card-custom card-stretch gutter-b" no-body>
    <b-card-header>
      <b-card-title class="font-weight-bolder">
        Comenzi active
      </b-card-title>
    </b-card-header>

    <b-card-body>
      <b-list-group>
        <b-list-group-item button v-for="order in orders" v-bind:key="order.id">
          <OrderWidget timestamp="created" v-bind:order="order" v-bind:disabled="true" v-bind:allows-cancel="true" v-on:cancel-order="cancelOrder" />
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<script>
import moment from "moment";

import api from "@/core/services/api";

import OrderWidget from "@/view/content/widgets/dispatch/Order.vue";

import { mapOrder } from "@/core/models/order";
import { mapStatus } from "../../core/models/order";

let subscriptions;

export default {
  name: "ActiveOrders",

  components: {
    OrderWidget
  },

  data() {
    return {
      orders: [],

      debounceDelay: moment.duration(250, "ms")
    };
  },

  methods: {
    async load() {
      this.loading = true;
      const response = await api.orders.activeOrders();
      this.loading = false;
      this.orders = response.map(mapOrder);
    },

    async cancelOrder(order) {
      // show confirmation dialog before cancelling an order

      this.$set(order, "cancelling", true);

      await api.orders.cancel(order.id);

      this.$set(order, "cancelling", false);
      this.$set(order, "status", "cancelled");
      this.$set(order, "reason", "cancelledByDispatch");
    },

    orderUpdated(event) {
      let currentOrder = this.orders.find(o => o.id === event.id);
      if (!currentOrder) {
        currentOrder = mapOrder(event);
      }
      
      const order = { ...currentOrder, ...event };
      if (event.status) {
        order.status = mapStatus(event.status);
        order.originalStatus = event.status;
      }
      const updatedOrders = [...this.orders];
      const orderIndex = updatedOrders.findIndex(item => item.id === order.id);
      if (orderIndex < 0) {
        updatedOrders.push(order);
      } else {
        updatedOrders[orderIndex] = order;
      }

      this.orders = updatedOrders;
    },

    removeOrder(orderId) {
      this.orders = this.orders.filter(order => order.id !== orderId);
    }
  },

  async created() {
    subscriptions = [
      api.orders.events.new.subscribe(event => this.orderUpdated(event)),
      api.orders.events.confirmed.subscribe(event => this.orderUpdated(event)),
      api.orders.events.cancelled.subscribe(event => this.removeOrder(event.id)),
      api.orders.events.arrived.subscribe(event => this.orderUpdated(event)),
      api.orders.events.late.subscribe(event => this.orderUpdated(event)),
      api.orders.events.completed.subscribe(event => this.removeOrder(event.id))
    ];
    await this.load();
  },
  destroyed() {
    subscriptions.forEach(sub => sub.unsubscribe());
  }
};
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    border-bottom: none;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-footer {
    .pagination {
      margin-bottom: 0;
    }

    .dropdown {
      margin-bottom: 0;
    }
  }
}
</style>
